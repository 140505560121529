<template>
  <v-container fluid class="no-connection red white--text">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        {{ $t('noConnection') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NoConntection'
}
</script>

<style scoped>
.no-connection {
  position: fixed;
  top: 0;
  z-index: 1200;
}
</style>
